import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {

render() {
	return(
		<div id="footer">
			<h1 className="title">Let's make stuff together.</h1>
			<p className="description">
				Contact:
			</p>
			<div className="icons-row">
				<a href="mailto: adam@adamleung.me" rel="noopener noreferrer" target="_blank"><i className="material-icons footer-icon" onClick={this.previous}>email</i></a>
				<a href="https://www.linkedin.com/in/adam-leung/" rel="noopener noreferrer" target="_blank"><img alt="LinkedIn" className="footer-icon" src={"/images/icons/linkedin.svg"} /></a>
				<a href="https://github.com/Centribo" rel="noopener noreferrer" target="_blank"><img alt="Github" className="footer-icon" src={"/images/icons/github.svg"} /></a>
			</div>

			{/* <p className="description">
				Social:
			</p>
			<div className="icons-row">
				<a href="http://www.instagram.com/iamcentribo" rel="noopener noreferrer" target="_blank"><img alt="Instagram" className="footer-icon" src={"/images/icons/instagram.svg"} /></a>
				<a href="https://www.youtube.com/centribo" rel="noopener noreferrer" target="_blank"><img alt="YouTube" className="footer-icon" src={"/images/icons/youtube.svg"} /></a>
				<a href="https://www.flickr.com/photos/centribo" rel="noopener noreferrer" target="_blank"><i className="material-icons footer-icon" onClick={this.previous}>photo_camera</i></a>
				<a href="https://twitter.com/Centribo" rel="noopener noreferrer" target="_blank"><img alt="YouTube" className="footer-icon" src={"/images/icons/twitter.svg"} /></a>
				<a href="https://www.twitch.tv/centribo" rel="noopener noreferrer" target="_blank"><img alt="YouTube" className="footer-icon" src={"/images/icons/twitch.svg"} /></a>
				<a href="https://www.last.fm/user/Centribo" rel="noopener noreferrer" target="_blank"><img alt="YouTube" className="footer-icon" src={"/images/icons/lastfm.svg"} /></a>
			</div> */}
			{/* OLD:
				src={"/images/icons/instagram.svg"}
				NEW:
				src={"/images/icons/instagram.svg"}
			*/}
		</div>
	);
}

}

export default Footer;