import React, { Component } from 'react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import './App.css';
import Banner from './Banner';
import ProjectGrid from './ProjectGrid';
import Footer from './Footer';

const initialState = {
	count: 0
};

function reducer(state = initialState, action){
	console.log('reducer', state, action);

	switch(action.type){
		case 'INCREMENT':
			return {
				count: state.count + 1
			};
		case 'DECREMENT':
			return {
				count: state.count - 1
			};
		default:
			return state;
	}
}

const store = createStore(reducer);

class App extends Component {

handleLoad(){
	// console.log('loaded');
	document.getElementById('spinner-container').remove();
	document.body.style.overflow = 'auto';
	var hiddenElements = document.getElementsByClassName('hidden');
	while(hiddenElements.length > 0){
		hiddenElements[0].classList.add('shown');
		hiddenElements[0].classList.remove('hidden');
	}
}

componentDidMount() {
	// console.log('mounted');
	// window.addEventListener('load', this.handleLoad);
	this.handleLoad();
}

render() {
	return (
		<Provider store={store}>
			<div className='App'>
				<Banner />
				<ProjectGrid />
				<Footer />
			</div>
		</Provider>
	);
}

}

export default App;
