import React, { Component } from 'react';
import './Banner.css';

class Banner extends Component {

scrollToProjects(e){
	document.querySelector('#projects-grid').scrollIntoView({ 
		behavior: 'smooth' 
	});
}

scrollToContact(e){
	document.querySelector('#footer').scrollIntoView({ 
		behavior: 'smooth' 
	});
}

render() {
	return(
		<div>
			<div id="nav-bar">
				<p className="hidden" onClick={this.scrollToProjects}>Projects</p>
				<p className="hidden" onClick={this.scrollToContact}>Contact</p>
			</div>
			<div id="banner">
				<div id="description">
					{/* Hi, I'm Adam Leung,</h1> */}
					<span id="summary" className="hidden">
						Hi! 👋
						<br></br>
						<br></br>
						I'm <b className="name-bold">Adam Leung</b>, a passionate gameplay programmer and designer currently in Toronto, Canada. I completed my Bachelor of Computer Science at the University of Waterloo in 2019. I'm currently the lead programmer at Maple Powered Games, working on <a href="/projects/monster-harvest">Monster Harvest</a>.
						<br></br>
						<br></br>
						If you'd like to work together, feel free to <a href="#footer">reach out</a>!
					</span>
				</div>
				<i id="scroll-indicator" className="material-icons hidden" onClick={this.scrollToProjects}>keyboard_arrow_down</i>
			</div>
		</div>
	);
}

}

export default Banner;