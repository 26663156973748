import React, { Component } from 'react';

import './Toolbar.css';

class Toolbar extends Component {

constructor() {
	super();
	this.state = {
		search: '',
		sort: 'date'
	};
}

updateSearch(event){
	this.setState({
		search: event.target.value
	});
	this.props.searchUpdated(event.target.value);
}

updateSort(event){
	this.setState({
		sort: event.target.value
	});
	this.props.sortUpdated(event.target.value);
}

render() {
	return(
		<div id="toolbar">
			<input id="search-bar" placeholder="Search: c#, unity, etc..." value={this.state.search} onChange={this.updateSearch.bind(this)} />
			<label id="sort-label"><i className="material-icons">sort</i></label>
			<select id="sort-dropdown" onChange={this.updateSort.bind(this)} >
				<option value="date">Date</option>
				<option value="name">Name</option>
			</select>
		</div>
	);
}

}

export default Toolbar;