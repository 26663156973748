import React, { Component } from 'react';
import Rodal from 'rodal';
import ProjectModal from './ProjectModal';

import './Animate.css';
import './ProjectThumbnail.css';
import './ProjectModal.css';

class ProjectThumbnail extends Component {

constructor(props) {
	super(props);
	
	var path = window.location.pathname.split('/');
	var visible = false;
	if(path[1] === 'projects' && path[2] === this.props.project.id){
		visible = true;
	}

	this.state = { visible: visible };

	// console.log(this.props.project);
	// console.log(window.location.pathname.split('/'));

}

show() {
	this.setState({ visible: true });
	window.history.pushState(null, "", "/projects/" + this.props.project.id);
}

hide() {
	this.setState({ visible: false });
	window.history.pushState(null, "", "/");
}


render() {
	var thumbnailBackgroundStyle = {
		// "backgroundImage": `url(${ process.env.PUBLIC_URL + this.props.project.thumbnail })`
		"backgroundImage": `url(${ '/' + this.props.project.thumbnail })`
	};

	var customModalProperties = {
		height: '100vh',
		width: '100vw'
	};

	return(
		<div className="project-thumbnail" id={this.props.project.id + '-thumbnail'} style={ thumbnailBackgroundStyle }>
			<div className="project-thumbnail-title-background" onClick={this.show.bind(this)}>
				<div className="project-thumbnail-title">{ this.props.project.name }</div>
			</div>

			<Rodal customStyles={customModalProperties} closeOnEsc={true} showCloseButton={false} animation="slideUp" visible={this.state.visible} onClose={this.hide.bind(this)}>

				<i className="material-icons rodal-close-icon animated infinite pulse delay-2s" onClick={this.hide.bind(this)}>close</i>
				<ProjectModal project={ this.props.project } />
			</Rodal>
		</div>
	);
}

}

export default ProjectThumbnail;

// { project.date.toLocaleDateString("en-US", { month: 'long', year: 'numeric'}) }