import React from 'react';
import Slider from 'react-slick';
import ReactMarkdown from 'react-markdown';

import CollaboratorData from './Data/CollaboratorData.json';

import './ProjectModal.css';

class ProjectModal extends React.Component {
	constructor(props){
		super(props);
		// See: https://react-slick.neostack.com/docs/example/previous-next-methods
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}

	next(){
		this.slider.slickNext();
	}

	previous(){
		this.slider.slickPrev();
	}

	render() {
		const projectImages = this.props.project.images.map((img) =>{
			// var imgUrl = process.env.PUBLIC_URL + img;
			var imgUrl = '/' + img;
			return(
				<img alt="" key={imgUrl} src={imgUrl} className="project-modal-image"></img>
			);
		});

		const projectCredits = this.props.project.credits.map((collaborator) =>{

			var credit = CollaboratorData.find(c => c.id === collaborator.id);

			return(
				<div className="credits" key={collaborator.id}>
					<div><a className="credit-name" href={ credit.website }>{ credit.firstName + " " + credit.lastName }</a></div>
					<div><i className="credit-contribution">{ collaborator.contribution }</i></div>
				</div>
			);
		});

		const date = new Date(this.props.project.date);
		const monthNames = [
			"January", "February", "March",
			"April", "May", "June", "July",
			"August", "September", "October",
			"November", "December"
		];
		const dateString = "(" + monthNames[date.getMonth()] + " " + date.getFullYear() + ")";

		const sliderSettings = {
			autoplay: true,
			autoplaySpeed: 5000,
			arrows: false,
			dots: true
		};

		return (
			<div className="project-modal">
				<div className="project-modal-carousel">
					{/* <i className="material-icons prev-button" onClick={this.previous}>arrow_back</i> */}
					<Slider ref={c=> (this.slider = c)} { ...sliderSettings }>
						{ projectImages }
					</Slider>
					{/* <i className="material-icons next-button" onClick={this.next}>arrow_forward</i> */}
				</div>
				<div className="project-modal-title">
					<span className="project-modal-title-name"> { this.props.project.name } </span>
					<span className="project-modal-title-date"> { dateString } </span>
				</div>
				{ this.props.project.link &&
					<div className="project-modal-link">
						<a href={this.props.project.link} rel="noopener noreferrer" target="_blank">Visit<i className="material-icons">link</i>
						</a>
					</div>
				}
				{/* <div className="project-modal-description"> <ReactMarkdown source={this.props.project.description} /> </div> */}
				<div className="project-modal-description"> <ReactMarkdown children={this.props.project.description} /> </div>
				<div className="project-modal-credits">{ projectCredits }</div>
			</div>
		);
		
	}
}

export default ProjectModal;