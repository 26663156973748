import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toolbar from './Toolbar';
import ProjectThumbnail from './ProjectThumbnail';
import ProjectData from './Data/ProjectData.json';

import './ProjectGrid.css';

class ProjectGrid extends Component {

constructor() {
	super();
	this.state = {
		search: '',
		sort: 'date'
	};
}

searchUpdated(e){
	this.setState({
		search: e
	});
}

sortUpdated(e){
	this.setState({
		sort: e
	});
}

yeet(){
	this.props.dispatch({type:"INCREMENT"});
}

render() {
	var searchTerms = this.state.search.toLowerCase().split(/[ ,]+/).filter(Boolean);
	
	const projectThumbnails = ProjectData
	.sort((a, b) => {
		switch(this.state.sort){
			case 'date':
			if(Date.parse(a.date) > Date.parse(b.date)){
				return -1;
			}
			return 1;

			case 'name':
			if(a.name < b.name){
				return -1;
			}
			return 1;

			default:
			return 0;
		}
	})
	.filter(p => {
		if(searchTerms.length === 0){
			return true;
		}

		var projName = p.name.toLowerCase();
		var category = p.category.toLowerCase();

		for(var i = 0; i < searchTerms.length; i++){
			if(projName.includes(searchTerms[i])){
				return true;
			}
			
			for(var j = 0; j < p.tags.length; j++){
				if(p.tags[j].toLowerCase().includes(searchTerms[i])){
					return true;
				}
			}
			
			if(category.includes(searchTerms[i])){
				return true;
			}
		}
		
		return false;
	});

	var games = projectThumbnails.filter(p => {
		if(p.category === "game"){
			return true;
		}

		return false;
	})
	.map((project) =>{
		return (
			<div key={ project.name }>
				<ProjectThumbnail project={ project } />
			</div>
		);
	});

	var tutorials = projectThumbnails.filter(p => {
		if(p.category === "tutorial"){
			return true;
		}

		return false;
	})
	.map((project) =>{
		return (
			<div key={ project.name }>
				<ProjectThumbnail project={ project } />
			</div>
		);
	});

	var other = projectThumbnails.filter(p => {
		if(p.category !== "tutorial" && p.category !== "game"){
			return true;
		}

		return false;
	})
	.map((project) =>{
		return (
			<div key={ project.name }>
				<ProjectThumbnail project={ project } />
			</div>
		);
	});

	return(
		<div id="projects-grid">
			<Toolbar searchUpdated={this.searchUpdated.bind(this)} sortUpdated={this.sortUpdated.bind(this)} />
			<div id="content-placeholder">
				<div className="category-title" onClick={this.yeet.bind(this)}>Games</div>
				<div className="projects">
					{ games }
				</div>
				<div className="category-title">Tutorials</div>
				<div className="projects">
					{ tutorials }
				</div>
				<div className="category-title">Other</div>
				<div className="projects">
					{ other }
				</div>
			</div>
		</div>
	);
}

}

function mapStateToProps(state){
	return {
		count: state.count
	};
}

export default connect(mapStateToProps)(ProjectGrid);

// export default ProjectGrid;